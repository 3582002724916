import "./HomeNew.scss";
import React, { useRef, useEffect, useState, Suspense, useContext } from "react";
import {
  getUserAuthToken,
  getApiUrl,
  listAllUnskippedResources,
  getExamSpaceDashboard,
  ExamSpaceUploadResource,
  getAllReviewQuestions,
  getAllConcepts,
  GetUserInfoAPI,
  CreateGtagEvent,
  getUserAnalyticsAPI,
  getAllExamSpaces
} from "../../helpers/requests";
import WaveSVG from "../../Components/WaveSVG/WaveSVG";
import lockIcon from "../../assets/lock.png";
import loadingsmall from "../../assets/loading-svg-orange.svg";
import weatherImg from "../../assets/cloudy.png";
import { useNavigate } from "react-router-dom";
import PaymentPopup from "../../Components/PaymentPopup/PaymentPopup";
import closeIcon from "../../assets/close.svg";
import uploadIcon from "../../assets/upload-white-icon.png";
import clearSkyIcon from "../../assets/weather/clear_sky.png";
import cloudsIcon from "../../assets/weather/clouds.png";
import mistIcon from "../../assets/weather/mist.png";
import rainIcon from "../../assets/weather/rain.png";
import snowIcon from "../../assets/weather/snow.png";
import thunderstormIcon from "../../assets/weather/thunderstorm.png";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Grid } from "swiper/modules";
import { ToastContainer, toast } from "react-toastify";
import { install } from "ga-gtag";
import style from "./home.module.css";
// import "swiper/css/bundle";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import Sidebar from "../../Components/Sidebar/Sidebar";
import newLockIcon from "../../assets/orange_lock.png";
import { CiMaximize1 } from "react-icons/ci";
import { RiArrowDropDownLine } from "react-icons/ri";
import ExamSpacePopup from "../../Components/ExamSpacePopup/ExamSpacePopup";
import GaugeChart from 'react-gauge-chart';
import orange_lock from "../../assets/orange_lock.png";
import { CurrentUserInfo } from "../../Components/ContextStore/ContextStore";
import FileUploadComponent from '../../Components/FileUploadComponent/FileUploadComponent';


const DonutChart = React.lazy(() => import('../../Components/DonutChart/DonutChart'));
const menuName = [
  {
    name: "correct",
    color: "#C4D7B2",
    id: 1,
  },
  {
    name: "wrong",
    color: "#FFB84C",
    id: 2,
  },
  {
    color: "#F0F5FF",
    name: "new",
    id: 3,
  },
]

const weatherIconMap = {
  clear_sky: clearSkyIcon,
  clouds: cloudsIcon,
  mist: mistIcon,
  rain: rainIcon,
  snow: snowIcon,
  thunderstorm: thunderstormIcon
}


export default function HomeNew() {
  const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
  const global_default_examspace_id = useRef("");
  let global_resource_id = useRef(null);
  const navigate = useNavigate();
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [weatherIconData, setWeatherIconData] = useState(clearSkyIcon);
  const [resource_available, set_resource_available] = useState(true);
  const [topic_available, set_topic_available] = useState(true);
  const [review_question_available, set_review_question_available] = useState(true);
  const [topic_list_array, Set_topic_list_array] = useState([]);
  const [question_list_array, Set_question_list_array] = useState([]);
  const [usertype, setUserType] = useState("");
  const [showloading, setShowLoading] = useState("");
  const [slide_count, set_slide_count] = useState(3);
  const [window_width, set_window_width] = useState(window.innerWidth);
  const [show_review_question_popup, Set_show_review_question_popup] = useState(
    {
      open: false,
      question: "",
      questionAns: "",
    }
  );
  const [feedback_popup_options, set_feedback_popup_options] = useState({
    open: false,
    type: `answer`,
    question_id: "",
  });
  const fileUploadRef = useRef(null);

  const [showExamspace, setShowExamSpace] = useState(false)
  const [showExamspaceDropMenu, setShowExamSpaceDropMenu] = useState(false)
  const [ExamSpacesList, setExamSpacesList] = useState([])
  const [selectedExamSpace, setSelectedExamSpace] = useState({})
  const [getUserInfo, setGetUserInfo] = useState([])
  const [dashBoardList, setDashBoardList] = useState([])


  useEffect(() => {
    proxyCallGetDefaultExamspaceIdUtility()

    if (window.innerWidth > 500) {
      set_slide_count(3);
    } else if (window.innerWidth > 490 && window.innerWidth <= 500) {
      set_slide_count(2);
    } else if (window.innerWidth <= 490) {
      set_slide_count(2);
    }

    const handleResize = () => {
      set_window_width(window.innerWidth);
      if (window.innerWidth > 500) {
        set_slide_count(3);
      } else if (window.innerWidth > 490 && window.innerWidth <= 500) {
        set_slide_count(2);
      } else if (window.innerWidth <= 490) {
        set_slide_count(2);
      }
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sharedUserInfo]);

  // useEffect(() => {
  //   console.log('called once again!!!!!!', sharedUserInfo)
  //   proxyCallGetDefaultExamspaceIdUtility();
  // }, [sharedUserInfo]);

  useEffect(() => {

    if (resource_available && topic_available) {
      getDashBoardData()
    }
  }, [resource_available, topic_available, sharedUserInfo])


  // async function proxyCallGetDefaultExamspaceIdUtility2(){
  //   let examspace_id = await getDefaultExamspaceIdUtility();
  // }


  async function proxyCallGetDefaultExamspaceIdUtility() {
    let examspace_id = await getDefaultExamspaceIdUtility();
    initialCallGetUserInfo()
    getAllExamSpacesList()
    // getDashBoardData()
    initialCallSection1()
  }

  //get Dashboard data
  const getDashBoardData = async () => {
    let dashBoardList = await getExamSpaceDashboard(global_default_examspace_id.current);
    if (dashBoardList.status) {
      setDashBoardList(dashBoardList.data);
      setWeatherIconData(weatherIconMap[dashBoardList.data[0]["weather_data"]])
    }
  }


  function initialCallGetUserInfo() {
    let get_user_info = localStorage.getItem('user_info')
    if (get_user_info) {
      setGetUserInfo(JSON.parse(get_user_info))
    }
  }

  function initialCallSection1() {
    if (
      apiUrl === "https://app.dende.ai/api" ||
      apiUrl === "https://staging.dende.ai/api"
    ) {
      console.log("G-K9ES37HT6F");
      install("G-K9ES37HT6F", { debugger: true, send_page_view: false });
    } else {
      console.log("G-GMWDDVX7G6");
      install("G-GMWDDVX7G6", { debugger: true, send_page_view: false });
    }
    checkForEmptyData();
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("paymentstatus") !== null && urlParams.get("paymentstatus") !== undefined) {
      if (urlParams.get("paymentstatus") === "true") {
        set_feedback_popup_options({
          open: true,
          type: `answer`,
          question_id: "",
        });
        getUserInfoAPI();
        handleMarketingData();
      }
    }
  }


  //Get all exam spaces List
  const getAllExamSpacesList = async () => {
    let examSpaceResponse = await getAllExamSpaces();
    console.log("examSpaceResponse", examSpaceResponse);
    if (examSpaceResponse.status) {
      setExamSpacesList(examSpaceResponse.data)
      if (examSpaceResponse.data.length) {
        for (let i of examSpaceResponse.data) {
          if (i.is_default) {
            setSelectedExamSpace(i)
          }
        }
      }
    }
  }



  async function handleMarketingData() {
    try {
      let userAnalyticsResponse = await getUserAnalyticsAPI();
      let userInfoResponse = await GetUserInfoAPI();
      let temp_user_id = String(localStorage.getItem("userId")) || "";
      let temp_plan_name = userInfoResponse.data?.[0]?.plan_type ?? "";
      let temp_plan_price = temp_plan_name === "30_days" ? "6.9" : temp_plan_name === "3_months" ? "17.40" : "";
      let temp_transaction_id = userInfoResponse.data?.[0]?.subscription_id ?? "";

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          transaction_id: temp_transaction_id,
          value: temp_plan_price,
          currency: "EUR",
          user_id: temp_user_id,
          first_name: userAnalyticsResponse?.data?.[0]?.first_name ?? "",
          surname: userAnalyticsResponse?.data?.[0]?.surname ?? "",
          email: userAnalyticsResponse?.data?.[0]?.email ?? "",
          phone: userAnalyticsResponse?.data?.[0]?.phone ?? "",
          street: userAnalyticsResponse?.data?.[0]?.street ?? "",
          city: userAnalyticsResponse?.data?.[0]?.city ?? "",
          country: userAnalyticsResponse?.data?.[0]?.country ?? "",
          postcode: userAnalyticsResponse?.data?.[0]?.postcode ?? "",
          items: [
            {
              item_name: temp_plan_name,
              item_id: temp_transaction_id,
              price: temp_plan_price,
              quantity: 1,
            },
          ]
        }
      });





    } catch (error) {
      console.log('pushmode page', error)
    }
  }


  async function getUserInfoAPI() {
    let response_var = await GetUserInfoAPI();
    if (response_var.data.length >= 0) {
      localStorage.setItem("plan_type", response_var.data[0].plan_type);
      localStorage.setItem("plan_end_date", response_var.data[0].plan_end_date);
    }
  }

  async function checkForEmptyData() {
    try {
      let resource_list_response = undefined;
      resource_list_response = await listAllUnskippedResources(global_default_examspace_id.current);
      if (resource_list_response !== undefined) {
        if (resource_list_response.data) {
          var unskipped_file_available = false;
          resource_list_response.data.forEach((resource_item) => {
            if (resource_item.unskipped_question_available === true) {
              unskipped_file_available = true;
            }
          });
          if (unskipped_file_available === true) {
            set_resource_available(true);

            let topic_list_response = await getAllConcepts(global_default_examspace_id.current);
            if (topic_list_response.data.length > 0 && topic_list_response.data[0]["topic_list"].length > 0) {
              set_topic_available(true);
              Set_topic_list_array(topic_list_response.data);
            } else {
              set_topic_available(false);
              pollTopicList();
            }

            let review_question_response = await getAllReviewQuestions(global_default_examspace_id.current);
            if (review_question_response.data.length > 0) {
              set_review_question_available(true);
              Set_question_list_array(review_question_response.data);
            } else {
              set_review_question_available(false);
            }

          } else {
            set_resource_available(false);
          }
        }
      }
    } catch (err) {
      console.log('home', err)
    }
  }

  async function pollTopicList() {
    let timeDuration = 0;

    let intervalId = setInterval(async () => {
      timeDuration += 10;
      if (timeDuration >= 600) {
        clearInterval(intervalId);
        return;
      }
      // let default_examspace_id = await getDefaultExamspaceIdUtility();
      let topic_list_response = await getAllConcepts(global_default_examspace_id.current);

      let getDashBoardList = await getExamSpaceDashboard(global_default_examspace_id.current);

      if (getDashBoardList.status) {

        let totalCount = getDashBoardList.data[0].examspace_num_correct + getDashBoardList.data[0].examspace_num_wrong + getDashBoardList.data[0].examspace_num_unanswered

        if (topic_list_response.data.length > 0 && topic_list_response.data[0]["topic_list"].length > 0 && totalCount >= 4) {
          set_topic_available(true);
          clearInterval(intervalId);
          // get_topic_list();
          let api_response = getAllReviewQuestions(global_default_examspace_id.current);
          Set_question_list_array(api_response.data);
        } else {
          set_topic_available(false);
        }

      }


    }, 10000);
  }

  useEffect(() => {
    var getUserType = localStorage.getItem("plan_type");
    if (getUserType) {
      setUserType(getUserType);
    }
  }, [question_list_array]);

  // function get_review_questions(){
  //   const requestOptions = {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + token,
  //     },
  //   };
  //   fetch(`${apiUrl}/get-review-questions`, requestOptions)
  //     .then((response) => response.json())
  //     .then((response) => {
  //       Set_question_list_array(response.data);
  //     });
  // }

  // async function get_topic_list() {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   global_resource_id.current = urlParams.get("resource_id");
  //   if (urlParams.get("resource_id")) {
  //     const body = { resource_id: global_resource_id.current };
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + token,
  //       },
  //       body: JSON.stringify(body),
  //     };
  //     fetch(`${apiUrl}/topics-list`, requestOptions)
  //       .then((response) => response.json())
  //       .then((response) => {
  //         Set_topic_list_array(response.data);
  //       });
  //   } else {

  //     // let default_examspace_id = await getDefaultExamspaceIdUtility()
  //     let topic_list_response = await getAllTopicList(global_default_examspace_id.current);
  //     if (topic_list_response.data.length > 0) {

  //       Set_topic_list_array(topic_list_response.data);
  //     }

  //     // const requestOptions = {
  //     //   method: "GET",
  //     //   headers: {
  //     //     "Content-Type": "application/json",
  //     //     Authorization: "Bearer " + token,
  //     //   },
  //     //   // body: JSON.stringify({}),
  //     // };
  //     // fetch(`${apiUrl}/home-topic-list`, requestOptions)
  //     //   .then((response) => response.json())
  //     //   .then((response) => {
  //     //     Set_topic_list_array(response.data);
  //     //   });
  //   }
  // }

  function handleOpenReview(questionid, questionname) {
    setShowLoading(questionid);
    // setLoaderStatus(true);
    const body = {
      question_id: questionid,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    };
    fetch(`${apiUrl}/review-content`, requestOptions)
      .then((response) => response.json())
      .then((response) => {

        setShowLoading("");
        if (response.data.length > 0) {
          Set_show_review_question_popup({
            open: true,
            question: questionname,
            questionAns: response.data[0].content,
          });
        } else {
          toast.success(response.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      });
  }

  function closeReviewQuestionPopup(target) {
    if (target.className === "topic-review-question-popup-container") {
      Set_show_review_question_popup(false);
    }
  }



  function handleUploadSuccess(response){
    checkForEmptyData();
  }

  function triggerFileUpload() {
    fileUploadRef.current.triggerFileInput();
  }

  // Function to get the day suffix (st, nd, rd, th)
  function getDaySuffix(day) {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  }

  // Options for toLocaleDateString
  const options = {
    weekday: 'long',    // Monday
    day: 'numeric',     // 14
    month: 'short'      // Feb
  };



  function handleTodayDate() {
    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', options);

    // Get the day and add the suffix
    const day = today.getDate();
    const dayWithSuffix = `${day}${getDaySuffix(day)}`;

    // Replace the day with the day and suffix
    const finalDate = formattedDate.replace(day, dayWithSuffix);
    return finalDate;
  }

  const handleClosePopUp = (props) => {
    setShowExamSpace(false)
  }

  const handleClickContinue = (id) => {
    navigate(`/quiz?topic_id=${id}`)
  }

  async function getDefaultExamspaceIdUtility() {
    return new Promise(async (resolve, reject) => {
      let default_examspace_id = "";
      if (Object.keys(sharedUserInfo).length > 0) {
        default_examspace_id = sharedUserInfo.default_examspace_id;
        global_default_examspace_id.current = sharedUserInfo.default_examspace_id;
        console.log('111111111111111111', sharedUserInfo.default_examspace_id)
        resolve(default_examspace_id);
      }
      else {
        let getResponse = await GetUserInfoAPI()
        if (getResponse.status) {
          if (getResponse.data.length > 0) {
            if (Object.keys(getResponse.data[0]).length > 0) {
              setSharedUserInfo(getResponse.data[0]);
              default_examspace_id = getResponse.data[0]["default_examspace_id"];
              global_default_examspace_id.current = getResponse.data[0]["default_examspace_id"];
              console.log('2222222222222222222222222', getResponse.data[0]["default_examspace_id"])
              resolve(default_examspace_id);
            }
          }
        }
      }

    });
  }


  return (
    <>
      {/* <Sidebar /> */}
      <main className="topic-container">
        <div className="topic-wrapper">
          <div>
            <div className={`${style["folder-list"]}`}>
              {getUserInfo.length ?
                <div>
                  <p className={style["user-message"]}>Hi {getUserInfo[0].name}, how you feel today? </p>
                  <p className={style["user-message-mobile"]}>Hi {getUserInfo[0].name} </p>
                  <div className={style["weather-container"]}>
                  <img src={weatherIconData} alt="weather image" className={style["weather-image"]} />
                    {console.log(`/weather/${weatherIconData}.png`)}
                    <p className={style["weather-message"]}>{handleTodayDate()}</p>
                  </div>

                </div>
                :
                null
              }
              <div className={style["exam-space-list"]}>
                {Object.keys(selectedExamSpace).length ?
                  <div
                    title='Select Examspace'
                    // onClick={() => setShowExamSpaceDropMenu(!showExamspaceDropMenu)}
                    onClick={() => setShowExamSpace('examSpace')}
                    className={style["exam-space-topic"]}>
                    <p>{selectedExamSpace.title}</p>
                    <CiMaximize1 className={style["max-icon"]} />
                    <RiArrowDropDownLine className={style["max-icon-mobile"]} />


                  </div>
                  :
                  null
                }
                {/* <div>
                  {showExamspaceDropMenu ?
                    <div className={style["exam-space-name-list"]}>
                      {ExamSpacesList.map((data) => (
                        <p
                          className={style["exam-space-name"]}>{data.title}</p>
                      ))}
                    </div>
                    :
                    null
                  }
                </div> */}
              </div>

            </div>
          </div>

          {console.log("resource_available", resource_available, topic_available, dashBoardList[0])}
          {resource_available ? (
            topic_available ? (
              <>
                <div className={style['folder-list-menu-display']}>
                  <div>
                    {dashBoardList[0] ?
                      <div className={style["content-card"]}>
                        <p className={style["content-card-title"]}>
                          {/* {dashBoardList[0].topic_name} */}
                          {dashBoardList[0].topic_name && dashBoardList[0].topic_name.length > 30
                            ? dashBoardList[0].topic_name.substring(
                              0,
                              30
                            ) + "..."
                            : dashBoardList[0].topic_name}
                        </p>
                        <p className={style["content-card-pages"]}>
                          {dashBoardList[0].topic_pages}

                          {dashBoardList[0].topic_pages.length ?
                            <>
                              &nbsp;|&nbsp;
                            </>
                            :
                            null
                          }

                          {dashBoardList[0].resource_name && dashBoardList[0].resource_name.length > 12 ?
                            dashBoardList[0].topic_name.substring(
                              0,
                              12
                            ) + "..." : dashBoardList[0].resource_name
                          }
                        </p>
                        <div
                          style={
                            dashBoardList[0].topic_score === 0
                              ? { width: "10%" }
                              : { width: dashBoardList[0].topic_score + "%" }
                          }
                          className={`${style["content-progress-bar"]}`}
                        ></div>
                        <hr className={style["content-card-continue-hr-line"]} />
                        <p onClick={() => handleClickContinue(dashBoardList[0].topic_id)} className={style["content-card-continue"]}>
                          Continue
                        </p>
                      </div>
                      :
                      null
                    }
                  </div>


                  <div>
                    {dashBoardList[0] ?
                      <>
                        <div className={style["content-card-chart"]}>
                          <div className={style["chart-menu-section"]}>
                            {menuName.map((menu) => (
                              <div className={style["chart-menu-heading"]}>
                                <div
                                  style={{ backgroundColor: menu.color }}
                                  className={style["chart-menu-color"]}>
                                  {/* {menu.name}  */}
                                </div>
                                <p
                                  className={style["chart-menu-text"]}>
                                  {menu.name}
                                </p>
                              </div>
                            ))}
                          </div>
                          {/* <GaugeChart id="gauge-chart4"
                            nrOfLevels={2}
                            arcPadding={0.1}
                            cornerRadius={3}
                            percent={.5}
                            colors={["#c4d7b2", "#feb84c"]}
                            textColor='#000000'
                            
                          /> */}

                          {/* <DonutChart correct={'15'} wrong={'5'} unanswered={'7'} /> */}

                          <Suspense fallback={<div>Loading...</div>}>

                            <DonutChart data={dashBoardList} />
                          </Suspense>
                          <hr className={style["content-card-continue-hr-line"]} />
                          {usertype == "free" ?
                            <div className={style['chart-menu-free-user']}>
                              <img onClick={() => navigate('/pushmode')} src={orange_lock} alt="lock button" />
                              <p onClick={() => navigate('/pushmode')} className={style["chart-menu-questions"]}>
                                Unlock all questions
                              </p>
                            </div>
                            :
                            <p onClick={() => navigate('/concept')} className={style["chart-menu-questions"]}>
                              See questions
                            </p>
                          }
                        </div>
                      </>
                      :
                      null
                    }
                  </div>
                </div>
              </>
            ) : (
              <>
                {/* Topic generating UI  */}
                <div className="home-new-topic-gen-container">
                  <img src={loadingsmall} alt="loading icon" />
                  <p>We are extracting the main concepts from your file</p>
                </div>
              </>
            )
          ) : (
            <>
              {/* file upload UI */}
              <div className="home-new-file-upload-container">
                <p>See the concepts you should master before the exam</p>
                <FileUploadComponent
                  ref={fileUploadRef}
                  onUploadSuccess={handleUploadSuccess}
                  examspaceId={global_default_examspace_id.current}
                />
                <button id="home_concepts_file_upload" onClick={triggerFileUpload}>
                  <img src={uploadIcon} alt="upload icon" />
                  Upload a file
                </button>
              </div>
            </>
          )}

          <section className="topic-review-section">
            <div className="topic-review-title">
              <h2>Explanations</h2>
            </div>

            {resource_available ? (
              review_question_available ? (
                // Normal UI
                <>
                  {usertype == "free" ? (
                    <div className="topic-review-questions-list">
                      {question_list_array.length ? (
                        <>
                          {question_list_array
                            .slice(0, 10)
                            .map((item, index) => {
                              return (
                                <>
                                  {index == 0 || index == 1 ? (
                                    <div className="topic-review-question">
                                      {/* <p>
                                      {item["question_name"]}
                                      <span className="PushMode">Read</span>
                                    </p> */}
                                      <div className="first-element">
                                        <p>{item["question_name"]}</p>
                                      </div>
                                      {showloading == item.question_id ? (
                                        <img
                                          width={"auto"}
                                          height={"35px"}
                                          style={{ marginLeft: "auto" }}
                                          className="topic-review-loading-gif"
                                          src={loadingsmall}
                                          alt="loading..."
                                        />
                                      ) : (
                                        <div className="topic-review-question-right">
                                          <span
                                            onClick={() => {
                                              handleOpenReview(
                                                item.question_id,
                                                item["question_name"]
                                              );
                                            }}
                                            className="PushMode"
                                          >
                                            Read
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="topic-review-question disable-question">
                                      <div
                                        onClick={() => {
                                          navigate(`/pushmode`);
                                        }}
                                      >
                                        <img
                                          style={{ height: "35px", opacity: 1 }}
                                          src={lockIcon}
                                          alt="lock icon"
                                        />
                                      </div>
                                      <div className="first-element">
                                        <p>{item["question_name"]}</p>
                                      </div>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                        </>
                      ) : null}
                    </div>
                  ) : null}
                  {usertype !== "free" ? (
                    <div className="topic-review-questions-list">
                      {question_list_array.length ? (
                        <>
                          {question_list_array
                            .slice(0, 10)
                            .map((item, index) => {
                              return (
                                <>
                                  <div className="topic-review-question">
                                    <div className="first-element">
                                      <p>{item["question_name"]}</p>
                                    </div>
                                    {showloading === item.question_id ? (
                                      <img
                                        width={"auto"}
                                        height={"35px"}
                                        style={{ marginLeft: "auto" }}
                                        src={loadingsmall}
                                        alt="loading..."
                                      />
                                    ) : (
                                      <div className="topic-review-question-right">
                                        <span
                                          onClick={() => {
                                            handleOpenReview(
                                              item.question_id,
                                              item["question_name"]
                                            );
                                          }}
                                          className="PushMode"
                                        >
                                          Read
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                            })}
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </>
              ) : (
                // generating review question UI
                <>
                  <div
                    style={{ marginBottom: "100px" }}
                    className="home-new-topic-gen-container"
                  >
                    <p style={{ marginTop: "0px" }}>
                      Reply to some questions to see what you need to review
                    </p>
                  </div>
                </>
              )
            ) : (
              // upload file UI
              <>
                <div className="home-new-file-upload-container">
                  <p>Upload at least 1 document to see your stats</p>
                  <FileUploadComponent
                    ref={fileUploadRef}
                    onUploadSuccess={handleUploadSuccess}
                    examspaceId={global_default_examspace_id.current}
                  />
                  <button id="home_explanation_file_upload" onClick={triggerFileUpload}>
                    <img src={uploadIcon} alt="upload icon" />
                    Upload a file
                  </button>
                </div>
              </>
            )}
          </section>
        </div>
      </main>





      <section
        className="topic-review-question-popup-container"
        style={
          show_review_question_popup.open
            ? { display: "block" }
            : { display: "none" }
        }
        onClick={(e) => {
          closeReviewQuestionPopup(e.target);
        }}
      >
        <div className="topic-review-question-popup-content">
          <img
            src={closeIcon}
            alt="close icon"
            onClick={() => {
              Set_show_review_question_popup(false);
            }}
          />
          <h6 className="topic-review-question-popup-title">
            {show_review_question_popup.question}
          </h6>
          <p className="topic-review-question-popup-explanation">
            {show_review_question_popup.questionAns}
          </p>
        </div>
      </section>

      <ExamSpacePopup data={ExamSpacesList} popup={showExamspace} close={handleClosePopUp} reCallGet={getAllExamSpacesList} />

      <PaymentPopup
        props={feedback_popup_options}
        nextQuiz={false}
      ></PaymentPopup>
      <LoaderSpinner data={loaderStatus}></LoaderSpinner>
      <ToastContainer />
    </>
  );
}
