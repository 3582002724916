import { useState, useEffect, useRef, useLayoutEffect, useContext } from "react";
import "./Concept.scss";
import triangleIcon from "../../assets/triangle.svg";
import { 
  getApiUrl,
  getUserAuthToken,
  getAllConcepts,
  listAllUnskippedResources,
  conceptRenameAPI,
  resourceRenameAPI,
  ExamSpaceUploadResource,
  GetUserInfoAPI
} from "../../helpers/requests";
import { useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import threeDotIcon from "../../assets/three-dot-menu-icon.png"
import closeIcon from "../../assets/small-close.png"
import smallSVG from "../../assets/loading-svg-orange.svg"
import uploadIcon from "../../assets/upload-white-icon.png"
import loadingsmall from "../../assets/loading-svg-orange.svg"
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { CurrentUserInfo } from "../../Components/ContextStore/ContextStore";
import FileUploadComponent from '../../Components/FileUploadComponent/FileUploadComponent';


export default function Concept() {
  const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
  const global_default_examspace_id = useRef("");

  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  const [conceptdata, setConceptData] = useState([]);
  const [filterdata, setFilterData] = useState([]);
  const [resourceid, setResourceId] = useState("");
  const [searchvalue, setSearchValue] = useState("");
  const [tempInterval, setTempInterval] = useState(0);
  const mobileContextMenuVisibility = useRef(null);
  const navigate = useNavigate();
  const mobileResponsiveTopicId = useRef(null);
  const [data_available, set_data_available] = useState(true);
  const [resource_available, set_resource_available] = useState(true);
  const [question_available, set_question_available] = useState(true);
  const [plan_type, set_plan_type] = useState(localStorage.getItem("plan_type"));
  const [question_count, set_question_count] = useState(null);
  const [topic_gif_visible, set_topic_gif_visible] = useState(false);
  const [concept_json_data_backup, set_concept_json_data_backup] = useState(false);
  const pollIntervalId = useRef(null);
  const conceptQuestionGenerationIntervalId = useRef(null);
  const conceptQuestionListIntervalId = useRef(null);
  const selectedResourceId = useRef("");
  const editConceptTitleOriginalInfo = useRef(null);
  const modifiedConceptTitle = useRef(null);
  const editResourceTitleOriginalInfo = useRef(null);
  const modifiedResourceTitle = useRef(null);
  const fileUploadRef = useRef(null);

    // mouse click event listener
    useEffect(()=>{
      proxyCallGetDefaultExamspaceIdUtility()
      const handleClick = (event) => {
        console.log('Mouse clicked:', event);
        if(editConceptTitleOriginalInfo.current !== null){
          if(event.target.className !== "concept-sub-title"){
            if(modifiedConceptTitle.current === editConceptTitleOriginalInfo.current["concept-title"]){
              editConceptTitleOriginalInfo.current = null;
              modifiedConceptTitle.current = null;
              resetConceptTitle();
            } else {
              handleConceptRename();
            }
          }
        }
        if(editResourceTitleOriginalInfo.current !== null){
          if(event.target.className !== "resource-title"){
            if(modifiedResourceTitle.current === editResourceTitleOriginalInfo.current["resource-title"]){
              editResourceTitleOriginalInfo.current = null;
              modifiedResourceTitle.current = null;
              resetResourceTitle();
            } else {
              handleResourceRename();
            }
          }
        }
      };
      document.addEventListener('click', handleClick);
      return()=>{
        document.removeEventListener('click', handleClick);
        clearInterval(pollIntervalId.current);
        clearInterval(conceptQuestionGenerationIntervalId.current);
        clearInterval(conceptQuestionListIntervalId.current);
        localStorage.removeItem("selectedResourceIdArray");
      }
    },[]);

    async function proxyCallGetDefaultExamspaceIdUtility(){
      let response = await getDefaultExamspaceIdUtility();
      checkEmptyState();
      pollData();
    }

    async function handleConceptRename(){
      let body = {
        "concept_id": editConceptTitleOriginalInfo.current["concept-id"],
        "concept_title": modifiedConceptTitle.current
      }
      if(modifiedConceptTitle.current.toString().length >= 3){
        let apiResponse = await conceptRenameAPI(body);
        if(apiResponse){
          editConceptTitleOriginalInfo.current = null;
          modifiedConceptTitle.current = null;
          getConceptsData()
          resetConceptTitle()
        }
      } else {
        toast.error("Concept title must be atleast 3 characters", {
          position: toast.POSITION.TOP_CENTER,
        });
        setConceptData([])
        getConceptsData()
        editConceptTitleOriginalInfo.current = null;
        modifiedConceptTitle.current = null;
        setTimeout(()=>{
          let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
          if(selectedResourceId != null && selectedResourceId != undefined){
            openTopicList(selectedResourceId)
          }
        },1000)
      }
    }

    async function getDefaultExamspaceIdUtility(){
      return new Promise( async(resolve, reject) => {
        let default_examspace_id = "";
        if( Object.keys(sharedUserInfo).length > 0){
          default_examspace_id = sharedUserInfo.default_examspace_id;
          resolve(default_examspace_id);
        } 
        else {
          let getResponse = await GetUserInfoAPI()
          if (getResponse.status) {
            if(getResponse.data.length > 0){
              if(Object.keys(getResponse.data[0]).length > 0){
                setSharedUserInfo(getResponse.data[0]);
                default_examspace_id = getResponse.data[0]["default_examspace_id"];
                resolve(default_examspace_id);
              }
            }
          }
        }
        
      });
    }

    async function handleResourceRename(){
      let body = {
        "resource_id": editResourceTitleOriginalInfo.current["resource-id"],
        "resource_title": modifiedResourceTitle.current
      }
      if(modifiedResourceTitle.current.toString().length >= 3){
        let apiResponse = await resourceRenameAPI(body);
        if(apiResponse){
          editResourceTitleOriginalInfo.current = null;
          modifiedResourceTitle.current = null;
          getConceptsData()
          resetResourceTitle()
        }
      } else {
        toast.error("File title must be atleast 3 characters", {
          position: toast.POSITION.TOP_CENTER,
        });        
        setConceptData([])
        getConceptsData()
        editResourceTitleOriginalInfo.current = null;
        modifiedResourceTitle.current = null;
        setTimeout(()=>{
          let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
          if(selectedResourceId != null && selectedResourceId != undefined){
            openTopicList(selectedResourceId)
          }
        },1000)        
      }
    }
  
  


  function pollData(){
      let timeDuration  = 0;
      pollIntervalId.current = setInterval(async ()=>{
          timeDuration += 20;
          if(timeDuration >= 600){
              clearInterval(pollIntervalId.current);
          }
          checkEmptyState();
      }, 20000);
  }

  async function checkEmptyState(){
      let resource_list_response = await listAllUnskippedResources(global_default_examspace_id.current);
      var unskipped_file_available = false;
      resource_list_response.data.forEach((resource_item)=>{
        if(resource_item.unskipped_question_available === true){
          unskipped_file_available = true;
        }
      })
      if(unskipped_file_available === true){
          set_resource_available(true);
          let conceptResponse = await getAllConcepts(global_default_examspace_id.current);
            if(conceptResponse.data.length > 0){
              // getConceptsData();
              if (conceptResponse.status) {
                setConceptData(conceptResponse.data);
                setFilterData(conceptResponse.data);
                if (conceptResponse.data.length) {
                  setTimeout(()=>{
                    openTopicList(conceptResponse.data[0].resource_id);
                  },100);
                  // openTopicList(conceptResponse.data[0].resource_id);
                  // if(selectedResourceId.current === ""){
                  //   setResourceId(conceptResponse.data[0].resource_id);
                  // } else {
                  //   setResourceId(selectedResourceId.current);
                  // }
                }
              }
              set_question_available(true);
              set_data_available(true);
              // checkConceptTopicList()
              checkConceptTopicListOnce(conceptResponse);
              checkQuestionGenerationOnce(conceptResponse);
              clearInterval(pollIntervalId.current);
            } else {
              set_question_available(false);
              set_data_available(false);
            }
      } else {
          set_resource_available(false);
          set_data_available(false);
      }
  }



  function getConceptsData(){
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    };
    fetch(`${apiUrl}/concepts`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          setConceptData(response.data);
          setFilterData(response.data);
          if (response.data.length) {
            if(selectedResourceId.current === ""){
              setResourceId(response.data[0].resource_id);
            } else {
              setResourceId(selectedResourceId.current);
            }
          }
        }
      });
  }

  function checkConceptTopicListOnce(res_data){
    let api_response = res_data;
    let total_files_count = Object.keys(api_response["data"]).length;
    let files_iterated_count = 0;
    let files_with_zero_topics = 0;
    api_response.data.forEach((item, index)=>{
      files_iterated_count += 1;
      if(item["topic_list"].length <= 0){
        files_with_zero_topics += 1;
      }
    
      if(files_iterated_count >= total_files_count){
        if(files_with_zero_topics <= 0){
          set_topic_gif_visible(false);
        
          // getConceptsData();
          // console.log('**************************************')
          // console.log('Question generated! loop stopped')
          // console.log('**************************************')
          // clearInterval(conceptQuestionListIntervalId.current);
          return;
        } else {
          checkConceptTopicList();
        }
      }
      
    });
  }

  async function checkConceptTopicList(){
    set_topic_gif_visible(true);
    let timeDuration = 0;
    conceptQuestionListIntervalId.current = setInterval(async ()=>{
      timeDuration += 20;
      if(timeDuration >= 600){
        clearInterval(conceptQuestionListIntervalId.current);
        return;
      }
      checkConceptTopicListLoop()
    }, 20000)
  }
  async function checkConceptTopicListLoop(){
      let api_response = await getAllConcepts(global_default_examspace_id.current);
      let total_files_count = Object.keys(api_response["data"]).length;
      let files_iterated_count = 0;
      let files_with_zero_topics = 0;
      api_response.data.forEach((item, index)=>{
        files_iterated_count += 1;
        if(item["topic_list"].length <= 0){
          files_with_zero_topics += 1;
        }
      
        if(files_iterated_count >= total_files_count){
          if(files_with_zero_topics <= 0){
            getConceptsData();
            checkQuestionGeneration();
          
            set_topic_gif_visible(false);
            clearInterval(conceptQuestionListIntervalId.current);
            return;
          }
        }
        
      });
  }

  function checkQuestionGenerationOnce(res_data){
    let api_response = res_data

    let total_topic_count = Object.keys(api_response["data"]).length;
    let topic_iterated_count = 0;
    let topic_with_less_questions = 0;

    api_response.data.forEach((item, index)=>{
      topic_iterated_count += 1;
      if(item["topic_list"].length > 0){
        // console.log(`topic: ${index+1} - Question_count: ${item["topic_list"][0]["question_count"]}`);

        if(item["topic_list"][0]["question_count"] < 1){
          topic_with_less_questions += 1;
        }

      

        if(topic_iterated_count >= total_topic_count){
          if(topic_with_less_questions <= 0){
            if (api_response.status) {
              setConceptData(api_response.data);
              setFilterData(api_response.data);
              if (api_response.data.length) {
                if(selectedResourceId.current === ""){
                  setResourceId(api_response.data[0].resource_id);
                } else {
                  setResourceId(selectedResourceId.current);
                }
              }
            }
            // getConceptsData();
            // console.log('**************************************')
            // console.log('Question generated! loop stopped')
            // console.log('**************************************')
            // clearInterval(conceptQuestionGenerationIntervalId.current);
            return;
          } else{
            checkQuestionGeneration();
          }
        }
      }
    });
  }


  async function checkQuestionGeneration(){
    let timeDuration = 0;

    conceptQuestionGenerationIntervalId.current = setInterval( async ()=>{
      timeDuration += 20;
      if(timeDuration >= 600){
        clearInterval(conceptQuestionGenerationIntervalId.current);
        return;
      }

      let api_response = await getAllConcepts(global_default_examspace_id.current);
      // console.log('api_response', api_response);
      // console.log('Topic Count: ', typeof(api_response["data"]))
      // console.log('Topic Count: ', Object.keys(api_response["data"]).length)

      let total_topic_count = Object.keys(api_response["data"]).length;
      let topic_iterated_count = 0;
      let topic_with_less_questions = 0;

      api_response.data.forEach((item, index)=>{
        topic_iterated_count += 1;
        if(item["topic_list"].length > 0){
          // console.log(`topic: ${index+1} - Question_count: ${item["topic_list"][0]["question_count"]}`);

          if(item["topic_list"][0]["question_count"] < 1){
            topic_with_less_questions += 1;
          }

      

          if(topic_iterated_count >= total_topic_count){
            if(topic_with_less_questions <= 0){
              getConceptsData();
             
              clearInterval(conceptQuestionGenerationIntervalId.current);
              return;
            }
          }
        }
      });
      
    }, 20000)
  }

  const handleSearch = (e) => {
    var inputValue = e.target.value;
    const filtered = filterdata[0].topic_list.filter((question) => {
      return question.name.toLowerCase().includes(inputValue.toLowerCase());
    });

    setConceptData([
      {
        resource_id: filterdata[0].resource_id,
        resource_title: filterdata[0].resource_title,
        topic_list: filtered,
      },
    ]);
    if(e.target.value === ""){
      getConceptsData();
    }
  };

  function openMobileContextMenu(target){

    let menu_element = target.parentNode.parentNode.parentNode.nextSibling;
 
    if(menu_element.style.display === "block"){
      menu_element.style.display = "none";
    } else if(menu_element.style.display === "" || menu_element.style.display === "none"){
      let menu_list = document.getElementsByClassName("concept-responsive-context-menu-container");
      Array.from(menu_list).forEach((element) => {
          element.style.display = "none";
      });
      menu_element.style.display = "block";
    }
  }

  function CloseMobileContextMenu(target){
    let menu = target.parentNode.parentNode;
    menu.style.display = "none";
  }

  function handleUploadSuccess(response){
    checkEmptyState();
  }

  function triggerFileUpload() {
    fileUploadRef.current.triggerFileInput();
  }

  function openTopicList(res_id){
    let topic_list_element = document.getElementById(`topic-wrapper-id-${res_id}`);
    let res_triangle_element = document.getElementById(`res-triangle-id-${res_id}`);
    // let res_array = localStorage.getItem("selectedResourceIdArray");
    // if(res_array == null || res_array == undefined){
    //   let temp_topic_array = [];
    //   temp_topic_array.push(res_id);
    //   localStorage.setItem("selectedResourceIdArray", JSON.stringify(temp_topic_array));
    // } else {
    //   res_array = JSON.parse(res_array);
    // }
    try {
      if(topic_list_element.style.display !== null | topic_list_element.style.display !== undefined){
        if(topic_list_element.style.display === "" || topic_list_element.style.display === "none" ){
          topic_list_element.style.display = "block";
          res_triangle_element.style.rotate = "90deg";
          // res_array.forEach((item)=>{
          //   openTopicList(item);
          // });
          // res_array.push(res_id)
          // localStorage.setItem("selectedResourceIdArray", JSON.stringify(res_array));
        } else {
          topic_list_element.style.display = "none";
          res_triangle_element.style.rotate = "0deg";
          // let updatedArray = res_array.filter(item => item !== res_id);
          // localStorage.setItem("selectedResourceIdArray", JSON.stringify(updatedArray));
        }
      }
    } catch(e){
      console.log(e)
    }
  }


  async function handleConceptTitleEdit(event, conceptInfo){
    resetConceptTitle(event);
    if(editConceptTitleOriginalInfo.current !== null){
      if(modifiedConceptTitle.current !== editConceptTitleOriginalInfo.current["concept-title"]){
        let body = {
          "concept_id": editConceptTitleOriginalInfo.current["concept-id"],
          "concept_title": modifiedConceptTitle.current
        }
        if(modifiedConceptTitle.current.toString().length >= 3){
          let apiResponse = await conceptRenameAPI(body);
          if(apiResponse){
            // new value set
            getConceptsData()
            setConceptTitleInfo(conceptInfo)
          }
        } else {
          toast.error("Concept title must be atleast 3 characters", {
            position: toast.POSITION.TOP_CENTER,
          });          
          setConceptData([])
          getConceptsData()
          setTimeout(()=>{
            let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
            if(selectedResourceId != null && selectedResourceId != undefined){
              openTopicList(selectedResourceId)
              editConceptTitleOriginalInfo.current = null;
              modifiedConceptTitle.current = null;
            }
          },1000)
        }
      } else {
        setConceptTitleInfo(conceptInfo)
      }
    } else{
      setConceptTitleInfo(conceptInfo)
    }
  }

  function setConceptTitleInfo(conceptInfo){
    editConceptTitleOriginalInfo.current = null;
    modifiedConceptTitle.current = null;
    editConceptTitleOriginalInfo.current = {
      "concept-id": conceptInfo.id,
      "concept-title": conceptInfo.name,
    }
    modifiedConceptTitle.current = conceptInfo.name
  }
  function resetConceptTitle(event=undefined){
    let tags = document.getElementsByClassName("concept-sub-title");
    Array.from(tags).forEach((element) => {
      element.style.boxShadow = "";
      element.style.backgroundColor = "#f0f5ff";
      element.style.padding = "0px";
    });
    if(event !== undefined){
      event.target.style.boxShadow = "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px";
      event.target.style.backgroundColor = "#fff";
      event.target.style.padding = "0px 5px";
    }
  }
  async function handleResourceTitleEdit(event, resourceInfo){
    resetResourceTitle(event);
    if(editResourceTitleOriginalInfo.current !== null){
      if(modifiedResourceTitle.current !== editResourceTitleOriginalInfo.current["resource-title"]){
        let body = {
          "resource_id": editResourceTitleOriginalInfo.current["resource-id"],
          "resource_title": modifiedResourceTitle.current
        }
        if(modifiedResourceTitle.current.toString().length >= 3){
          let apiResponse = await resourceRenameAPI(body);
          if(apiResponse){
            // new value set
            getConceptsData()
            setResourceTitleInfo(resourceInfo)
          }
        } else {
          toast.error("File title must be atleast 3 characters", {
            position: toast.POSITION.TOP_CENTER,
          });          
          setConceptData([])
          getConceptsData()
          setTimeout(()=>{
            let selectedResourceId = localStorage.getItem("lastSelectedResourceId");
            if(selectedResourceId != null && selectedResourceId != undefined){
              openTopicList(selectedResourceId)
              editResourceTitleOriginalInfo.current = null;
              modifiedResourceTitle.current = null;
            }
          },1000)
        }
      } else {
        setResourceTitleInfo(resourceInfo)
      }
    } else{
      setResourceTitleInfo(resourceInfo)
    }
  }

  function setResourceTitleInfo(resourceInfo){
    editResourceTitleOriginalInfo.current = null;
    modifiedResourceTitle.current = null;
    editResourceTitleOriginalInfo.current = {
      "resource-id": resourceInfo.resource_id,
      "resource-title": resourceInfo.resource_title,
    }
    modifiedResourceTitle.current = resourceInfo.resource_title
  }
  function resetResourceTitle(event=undefined){
    let tags = document.getElementsByClassName("resource-title");
    Array.from(tags).forEach((element) => {
      element.style.boxShadow = "";
      element.style.backgroundColor = "#fff";
      element.style.padding = "0px";
    });
    if(event !== undefined){
      event.target.style.boxShadow = "rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px";
      event.target.style.backgroundColor = "#fff";
      event.target.style.padding = "0px 5px";
    }
  }

  async function getDefaultExamspaceIdUtility(){
    return new Promise( async(resolve, reject) => {
      let default_examspace_id = "";
      if( Object.keys(sharedUserInfo).length > 0){
        default_examspace_id = sharedUserInfo.default_examspace_id;
        global_default_examspace_id.current = sharedUserInfo.default_examspace_id;
        console.log('111111111111111111', sharedUserInfo.default_examspace_id)
        resolve(default_examspace_id);
      } 
      else {
        let getResponse = await GetUserInfoAPI()
        if (getResponse.status) {
          if(getResponse.data.length > 0){
            if(Object.keys(getResponse.data[0]).length > 0){
              setSharedUserInfo(getResponse.data[0]);
              default_examspace_id = getResponse.data[0]["default_examspace_id"];
              global_default_examspace_id.current = getResponse.data[0]["default_examspace_id"];
              console.log('2222222222222222222222222', getResponse.data[0]["default_examspace_id"])
              resolve(default_examspace_id);
            }
          }
        }
      }
      
    });
  }  



  return (
    <>
    {/* <Sidebar/> */}
      <main className="concept-container">
        <div className="concept-wrapper">
          <div className="concept-title">
            <h2>Concepts</h2>
            {/* <div className="search-box">
              <input placeholder="search for keywords" type="text" />
            </div> */}
            <div className="search">
              <div className="icon">
                <BsSearch />
              </div>
              <input
                onChange={(e) => handleSearch(e)}
                // onChange={(e) => setSearchValue(e.target.value)}
                type="text"
                placeholder="Search for keywords"
              />
            </div>
          </div>

          <section className="concept-empty-state-container"
                style={data_available === false ? {display: "flex"} : {display: "none"}}>
                  {/* {console.log('data_available', data_available)} */}
                <div className="quiz-empty-state-content">
                    {/* upload file UI */}
                    <div className="quiz-empty-state-upload-file" 
                    style={resource_available === false ? {display: "block"} : {display: "none"}}> 
                    <p>Upload at least 1 document to see the main concepts to learn</p>
                    <FileUploadComponent
                      ref={fileUploadRef}
                      onUploadSuccess={handleUploadSuccess}
                      examspaceId={global_default_examspace_id.current}
                    />
                    <button onClick={triggerFileUpload}>
                      <img src={uploadIcon} alt="upload icon"/>
                      Upload a file
                    </button>
                    </div>
                    {/* Generate question UI */}
                    <div className="quiz-empty-state-generate"
                        style={question_available === false ? {display: "block"} : {display: "none"}}>
                        <img src={loadingsmall} alt="loading" />

                        <p>We are extracting the main concepts from your document</p>
                    </div>
                </div>
          </section>
          {
            conceptdata.length
            ?
              <>
                {/* {openTopicList(conceptdata[0].resource_id)} */}
                <div className="concept-content"
                  style={data_available === true ? {display: "block"} : {display: "none"}}>
                  {conceptdata.map((data, resIndex) => (
                    <article className="concept-file-card" key={resIndex}>
                      <div
                        id="concepts_document_toggle"
                        className="concept-filename"
                      >
                        <p
                          className="resource-title"
                          onClick={(e)=>{handleResourceTitleEdit(e, data); localStorage.setItem("lastSelectedResourceId", data.resource_id)}}
                          onInput={(e)=>{
                            modifiedResourceTitle.current = e.target.innerText;
                          }}
                          contentEditable={true}
                          suppressContentEditableWarning={true}
                        >
                          {data.resource_title}
                        </p>
                        <div
                          className="concept-filename-triangle-container"
                          onClick={(e)=>{openTopicList(data.resource_id)}}
                        >
                          <img
                            id={`res-triangle-id-${data.resource_id}`}
                            src={triangleIcon}
                            alt="triangle icon"
                          />
                        </div>
                      </div>
                      {
                        data.topic_list.length <= 0  && topic_gif_visible === true?
                        <>
                          <div className="concept-file-individual-topic-loading"
                            style={resourceid === data.resource_id ? {display: "block"} : {display: "none"}}>
                              <img src={loadingsmall} alt="loading" />
                              <p>We are extracting the main concepts from your document</p>
                          </div>
                        </>
                        :
                        <>
                          <div className="concept-file-topics-list">
                            <section  style={{display: "none", marginTop: "20px"}} id={`topic-wrapper-id-${data.resource_id}`}>
                              <div
                                id="concepts_document_questions_unlock"
                                onClick={()=>{navigate("/pushmode")}}
                                style={ plan_type === "free" ? {display: "block"} : {display: "none"}}
                                className="unlock-question-container"
                              >
                                  Unlock <span >+{data.total_question_count} questions</span> from this document
                              </div>
                              {data.topic_list.map((topic, index1) => (
                              <>
                                <div className={"concept-file-topic"}>
                                  <div style={{borderBottom: "1px solid #d6d6d6", paddingBottom: "10px"}}>
                                    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                      <div className="concept-file-topic-left">
                                        <div className="concept-name" style={topic.status === "pending" ? {opacity: "1", pointerEvents: "auto"} : {opacity: "0.3", pointerEvents: "none"}}>
                                          {/* concept title */}
                                          <h4 
                                            className="concept-sub-title"
                                            contentEditable={true}
                                            suppressContentEditableWarning={true}
                                            onClick={(e)=>{handleConceptTitleEdit(e, topic); localStorage.setItem("lastSelectedResourceId", data.resource_id)}}
                                            onInput={(e)=>{
                                              modifiedConceptTitle.current = e.target.innerText                                           
                                            }}
                                          >
                                            {topic.name}
                                          </h4>
                                          <p>
                                            {
                                              topic.pages === ""
                                                ?
                                                  ""
                                                :
                                                  "pages "+topic.pages
                                            }
                                          
                                          </p>
                                        </div>

                                        <div className="concept-progress-bar-container concept-progress-bar-responsive-inactive"
                                          style={topic.status === "pending" ? {opacity: "1"} : {opacity: "0.3"}}>
                                          <div style={ topic.score > 100 ? {width: "100%"} :  { width: topic.score + "%" }} className="progress-bar-inner"></div>
                                          {/* <div style={{width: "50%"}} className="progress-bar-inner"></div> */}
                                        </div>

                                        {topic.status === "pending" ?
                                          topic.question_total_count > 0 ? (
                                            <>
                                              <div className="concept-progress-bar-container concept-progress-bar-responsive-active">
                                                <div style={ topic.score > 100 ? {width: "100%"} :  { width: topic.score + "%" }} className="progress-bar-inner">
                                                </div>
                                              </div>
                                              <div className="concept-three-dot-icon concept-progress-bar-responsive-active" 
                                                // onClick={()=>{openMobileContextMenu(topic, `concept-responsive-context-menu-container-${index1}`);}}>
                                                onClick={(e)=>{openMobileContextMenu(e.target);}}>
                                                <img src={threeDotIcon} alt="three dot icon" />
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div className="concept-gen-question-gif-container concept-progress-bar-responsive-active">
                                                <div className="concept-gen-question-gif">
                                                  <img src={smallSVG} alt="loading svg" />
                                                  <p>Generating questions</p>
                                                </div>
                                              </div>
                                            </>
                                          )

                                        : (
                                            <div id="concepts_go_to_pushmode" onClick={() => navigate(`/pushmode`)} 
                                              className="concept-responsive-pushmode-btn concept-progress-bar-responsive-active">
                                              Go push mode
                                            </div>
                                          ) 
                                        }
                                      </div> 
                                      <div className="concept-file-topic-right">
                                      {topic.status === "pending" ? 
                                        topic.question_total_count > 0 ? (
                                          <>
                                            <p 
                                            id="concepts_take_the_quiz" 
                                            onClick={() =>navigate(`/quiz?topic_id=${topic.id}`)} className="quiz-btn">
                                              Take the quiz&nbsp;{`>`}
                                            </p>
                                            <p id="concepts_see_question" onClick={() => navigate(`/conceptquestion?topic_id=${topic.id}`)} className="question-btn">
                                              {`See  ${topic.question_total_count} questions > `}
                                            </p>
                                          </>
                                        ) : (
                                          <>
                                            <div className="concept-gen-question-gif">
                                              <img src={smallSVG} alt="loading svg" />
                                              <p>Generating questions</p>
                                            </div>
                                          </>
                                        )
                                      : (
                                        <>
                                          <div id="concepts_go_to_pushmode" onClick={() => navigate(`/pushmode`)} className="concept-push-mode-block-right">
                                            <button>Go push mode</button>
                                          </div>
                                        </>
                                      )}
                                      </div>
                                    </div>
                                    <div className="concept-responsive-context-menu-container">
                                      <div className="concept-responsive-context-menu">
                                        <button id="concepts_take_the_quiz" onClick={()=>{ navigate( `/quiz?topic_id=${topic.id}`)}}>Take the quiz</button>
                                        <button id="concepts_see_question" onClick={()=>{ navigate( `/conceptquestion?topic_id=${topic.id}`)}}>See {topic.question_total_count} questions</button>
                                        <img onClick={(e)=>{CloseMobileContextMenu(e.target)}} 
                                          src={closeIcon} alt="close icon" />
                                      </div>
                                    </div>  
                                  </div>
                                </div>
                              </>
                              ))}
                            </section>
                        </div>
                        </>
                      }

                    </article>
                  ))}
                </div>
              </>
            :
              <></>
          }
        </div>
      </main>
      <ToastContainer />
    </>
  );
}
