import React, { useEffect, useRef, useState, useContext } from "react";
import { BsSearch } from "react-icons/bs";
import Filter from "../../Components/Filter/Filter";
import {
  AiOutlineClose,
  AiOutlineRight,
  AiOutlineArrowLeft,
} from "react-icons/ai";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "react-dropdown";
import { 
  getUserAuthToken,
  getApiUrl,
  uploadResource,
  ExamSpaceUploadResource,
  listAllUnskippedResources,
  GetUserInfoAPI,
  getLibraryAPI,
} from "../../helpers/requests";
import "react-dropdown/style.css";
import { masterRequest } from "../../helpers/requests";
import LoaderSpinner from "../../Components/LoaderSpinner/LoaderSpinner";
import { useNavigate } from "react-router-dom";
import style from "./Library.module.scss";
import threeDotIcon from "../../assets/three-dot-menu-icon.png"
import closeIcon from "../../assets/small-close.png"
import closeWhiteIcon from "../../assets/small-close-white-icon.png"
import uploadIcon from "../../assets/upload-white-icon.png"
import loadingsmall from "../../assets/loading-svg-orange.svg"
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../../Components/Sidebar/Sidebar";
import { CurrentUserInfo } from "../../Components/ContextStore/ContextStore";
import FileUploadComponent from '../../Components/FileUploadComponent/FileUploadComponent';


const Library = () => {
  const { sharedUserInfo, setSharedUserInfo } = useContext(CurrentUserInfo);
  const global_default_examspace_id = useRef("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const raw = useSelector((state) => state.master.questions);
  // console.log("---------raw @ top", raw)
  var token = getUserAuthToken();
  var apiUrl = getApiUrl();
  let [library, setLibrary] = useState([]);
  let [questions, setQuestions] = useState([]);
  let [answers, setAnswers] = useState([]);
  const [searchString, setSearchString] = useState("");
  const options = ["All", "MCQ", "Flashcard", "Open"];
  const statuses = ["All", "Draft", "Completed"];
  const [option, setOption] = useState(options[0]);
  const [status, setStatus] = useState(statuses[0]);
  const [showMobileActions, setShowMobileActions] = useState(statuses[0]);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [selectid, setSelectId] = useState({
    id: "",
    question_id: "",
  });
  const [data_available, set_data_available] = useState(true);
  const [resource_available, set_resource_available] = useState(true);
  const [question_available, set_question_available] = useState(true);
  //newArray
  const [getdata, setGetData] = useState([]);
  const [filterdata, setFilterData] = useState([]);
  const [showid, setShowId] = useState("");
  const pollIntervalId = useRef(null);
  const fileUploadRef = useRef(null);

  useEffect(()=>{
    // checkEmptyState();
    // pollData();
    proxyCallGetDefaultExamspaceIdUtility()
    return ()=>{
      clearInterval(pollIntervalId.current);
    }
  }, []);


  async function proxyCallGetDefaultExamspaceIdUtility(){
    let examspace_id = await getDefaultExamspaceIdUtility();
    checkEmptyState()
  }

  // async function getLibraryQuestions(){
  //   setLoaderStatus(true);
  //   let libraryAPIResponse = await getLibraryAPI(global_default_examspace_id.current);
  //   if(libraryAPIResponse.status){
  //     if(libraryAPIResponse.data.length > 0){
  //       setLoaderStatus(false);
  //       setGetData(libraryAPIResponse.data);
  //       setFilterData(libraryAPIResponse.data);
  //     } else {
  //       setLoaderStatus(false);
  //       pollData();
  //     }
  //   }
  // }

  function pollData(){
    let timeDuration  = 0;
    pollIntervalId.current = setInterval(async ()=>{
      timeDuration += 20;
      if(timeDuration >= 600){
        clearInterval(pollIntervalId.current);
      }
      checkEmptyState();
    }, 20000);
  }

  async function checkEmptyState(){
    console.log('listAllUnskippedResources called once')
    let resource_list_response = await listAllUnskippedResources(global_default_examspace_id.current);
    if(resource_list_response.data.length > 0){
      set_resource_available(true);
      setLoaderStatus(true);
      let libraryAPIResponse = await getLibraryAPI(global_default_examspace_id.current);
      if(libraryAPIResponse.data.length > 0){
        setLoaderStatus(false);
        set_question_available(true);
        set_data_available(true);
        clearInterval(pollIntervalId.current);
        setGetData(libraryAPIResponse.data);
        setFilterData(libraryAPIResponse.data);
      } else {
        setLoaderStatus(false);
        set_question_available(false);
        set_data_available(false);
        clearInterval(pollIntervalId.current);
      }
    } else {
      set_resource_available(false);
      set_data_available(false);
    }
  }

  function handleUploadSuccess(response){
    checkEmptyState();
  }

  function triggerFileUpload() {
    fileUploadRef.current.triggerFileInput();
  }

  useEffect(() => {
    let filteredQuestions = [...library];
    if (option && option != "All") {
      filteredQuestions = filteredQuestions.filter(
        (question) => question.type.toUpperCase() == option.toUpperCase()
      );
    }
    if (status && status != "All") {
      if (status == "Draft") {
      
        filteredQuestions = filteredQuestions.filter(
          (question) =>
            question.draft &&
            question.draft.length > 0 &&
            !question.solution_answer &&
            !question.solution_answer_text
        );
      } else {
        filteredQuestions = filteredQuestions.filter(
          (question) => !question.draft
        );
      }
    }
    if (searchString && searchString.length > 3) {
      filteredQuestions = filteredQuestions.filter(
        (question) =>
          question.statement.toLowerCase().indexOf(searchString.toLowerCase()) >
          -1
      );
    }
    const showAnswers = {};
    filteredQuestions.forEach((q) => {
      showAnswers[q.id] = false;
    });
    setAnswers(showAnswers);
    setQuestions(filteredQuestions);
  }, [status, option, searchString]);

  useEffect(() => {
    let attempted = [
      ...raw.MCQ.attempted,
      ...raw.open.attempted,
      ...raw.flashcard.attempted,
    ];
    setLibrary(attempted);
    setQuestions(attempted);
  }, [raw]);

  const toggleStatus = (id, status) => {
    const showStatus = { ...answers };
    showStatus[id] = status;
    setAnswers(showStatus);
    setShowMobileActions(!showMobileActions);
  };


  const handleSearch = (e) => {
    setSearchString(e.target.value);
    const filtered = filterdata.filter((question) => {
      return question.question_content.question
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setGetData(filtered);
   
  };

  function handleShowOption(id, q_id) {
    if (selectid.id == id) {
      setSelectId({
        id: "",
        question_id: "",
      });
    } else {
      setSelectId({
        id: id,
        question_id: q_id,
      });
    }
  }

  function showQuestionAnswer(id_reference){
    let btn_list = document.getElementsByClassName(style["answer-container"]);
    Array.from(btn_list).forEach((element) => {
        element.style.display = "none";
    });
    let answer_container = document.getElementById(id_reference);
    answer_container.style.display = "block";
    
  }
  function hideQuestionAnswer(id_reference){
    let answer_container = document.getElementById(id_reference);
    answer_container.style.display = "none";
   
  }

  function showMobileMenu(id_reference){
    let btn_list = document.getElementsByClassName(style["mobile-menu-container"]);
    Array.from(btn_list).forEach((element) => {
        element.style.display = "none";
    });
    let mobile_menu = document.getElementById(id_reference);
    mobile_menu.style.display = "block";
    
  }
  function hideMobileMenu(id_reference){
    let mobile_menu = document.getElementById(id_reference);
    mobile_menu.style.display = "none";
   
  }

  async function getDefaultExamspaceIdUtility(){
    return new Promise( async(resolve, reject) => {
      let default_examspace_id = "";
      if( Object.keys(sharedUserInfo).length > 0){
        default_examspace_id = sharedUserInfo.default_examspace_id;
        global_default_examspace_id.current = sharedUserInfo.default_examspace_id;
        console.log('111111111111111111', sharedUserInfo.default_examspace_id)
        resolve(default_examspace_id);
      } 
      else {
        let getResponse = await GetUserInfoAPI()
        if (getResponse.status) {
          if(getResponse.data.length > 0){
            if(Object.keys(getResponse.data[0]).length > 0){
              setSharedUserInfo(getResponse.data[0]);
              default_examspace_id = getResponse.data[0]["default_examspace_id"];
              global_default_examspace_id.current = getResponse.data[0]["default_examspace_id"];
              console.log('2222222222222222222222222', getResponse.data[0]["default_examspace_id"])
              resolve(default_examspace_id);
            }
          }
        }
      }
      
    });
  }  

  return (
    <>
    {/* <Sidebar/> */}
    <div className={style["main_library"]}>
      <div className={style["library_content"]}>
        <div className={style["librar_topsec"]}>
          <div className={style["left_sec"]}>
            <div className={style["title"]}>
              {" "}
              Library
              <span>
                ({getdata.length} question{getdata.length == 1 ? "" : "s"})
              </span>
            </div>
          </div>
          <div className={style["right_sec"]}>
            <div className={style["search"]}>
              <div className={style["icon"]}>
                <BsSearch />
              </div>
              <input
                onChange={(e) => handleSearch(e)}
                type="text"
                placeholder="Search for keywords"
              />
            </div>
            <div></div>
          </div>
        </div>
        <section className={style["library-empty-state-container"]}
          style={data_available === false ? {display: "flex"} : {display: "none"}}>
            <div className={style["library-empty-state-content"]}>
              {/* upload file UI */}
              <div className={style["library-empty-state-upload-file" ]}
                style={resource_available === false ? {display: "block"} : {display: "none"}}> 
                <p>Ready to see all the questions and correct answers?</p>
                <FileUploadComponent
                  ref={fileUploadRef}
                  onUploadSuccess={handleUploadSuccess}
                  examspaceId={global_default_examspace_id.current}
                />
                <button onClick={triggerFileUpload}>
                    <img src={uploadIcon} alt="upload icon"/>
                    Upload a file
                </button>
              </div>
              {/* Generating questions UI */}
              <div className={style["library-empty-state-generate"]}
                style={question_available === false ? {display: "block"} : {display: "none"}}>
                <p>We will save your questions here. Just start practicing</p>
                <button onClick={()=>{navigate("/quiz")}}>Go to Quiz</button>
              </div>
            </div>
        </section>
        <div className={style["question-list-container"]}
          style={data_available === true ? {display: "block"} : {display: "none"}}>
          { getdata.length ? 
            getdata.map((question_item, index)=>{
              return(
                <>
                  <div className={style["question-card-wrapper"]}>
                    {/* Question */}
                    <article className={style["question-card"]} key={index}>
                      <div className={style["question-title"]}>
                          <p>{question_item["question_content"]["question"]}</p>
                      </div>
                      <div className={style["question-menu"]}>
                        <button id="library_see_answer" className={style["question-btn"]} style={{marginRight: "20px"}}
                        onClick={(e)=>{showQuestionAnswer(`answer-container-${index}`)}}>
                          See Answer{` >`}
                        </button>
                        <button id="library_reply" className={style["question-btn"]} onClick={()=>{
                          navigate(`/quiz?question_id=${question_item["question_id"]}&library=true`);
                        }}>Reply {` >`}</button>
                      </div>
                      <img className={style["question-mobile-menu-icon"]} src={threeDotIcon} alt="three dot" 
                        onClick={(e)=>{showMobileMenu(`mobile-menu-${index}`)}} />
                    </article>
                    {/* mobile menu */}
                    <div className={style["mobile-menu-container"]} id={`mobile-menu-${index}`}>
                      <div className={style["mobile-menu-content"]}>                        
                        <button id="library_see_answer" onClick={(e)=>{showQuestionAnswer(`answer-container-${index}`)}}>See Answer</button>
                        <button id="library_reply" onClick={()=>{ navigate(`/quiz?question_id=${question_item["question_id"]}&library=true`);}}>Reply</button>
                        <img src={closeIcon} alt="close icon" onClick={(e)=>{hideMobileMenu(`mobile-menu-${index}`)}}/>
                      </div>
                    </div>
                    {/* answer */}
                    <div className={style["answer-container"]} id={`answer-container-${index}`}>
                      <div className={style["answer-content"]}>
                        {question_item["question_content"]["options"].map((item, index)=>{
                          if(item["is_correct"] === true){
                            return(
                              <div className={style["answer-text"]} key={index}>
                                {item["text"]}
                              </div>
                            )
                          }
                        })}
                        <img className={style["answer-close-icon"]} 
                          onClick={(e)=>{hideQuestionAnswer(`answer-container-${index}`)}} src={closeWhiteIcon} alt="close icon" />
                      </div>
                    </div>
                  </div>
                </>
              )
            })
          : null}

        </div>
      </div>
      <LoaderSpinner data={loaderStatus}></LoaderSpinner>
      <ToastContainer />
    </div>
    </>
  );
};

export default Library;
