import { useEffect, useRef, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  GetUserInfoAPI,
  updateUserProfile,
  getPaymentHistory,
  cancelSubscription,
} from "../../helpers/requests";
import style from "./topNotificationBanner.module.scss";
import questionIcon from "../../assets/question.png";
import { Message_data } from "../ContextStore/ContextStore";

export default function TopNotificationBanner() {
  const { questionData } = useContext(Message_data);

  const [show_pushmode_bar, set_show_pushmode_bar] = useState(false);
  const [show_question_counter_bar, set_show_question_counter_bar] =
    useState(false);
  const [subscription_status, set_subscription_status] = useState(null);
  const [window_width, set_window_width] = useState(window.innerWidth);

  const question_counter_helptext = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();

  // component initialize
  useEffect(() => {
    getUserInfo();
  }, []);

  // call user info api
  async function getUserInfo() {
    try{
      let user_info_res = undefined;
      user_info_res = await GetUserInfoAPI();
      if(user_info_res !== undefined){
        if(user_info_res.data && user_info_res.data.length > 0){
          set_subscription_status(user_info_res.data[0]["subscription_status"]);
          let plan_end_date_with_time = new Date(user_info_res.data[0]["plan_end_date"]);
          let plan_end_date_without_time = plan_end_date_with_time.setHours(0,0,0,0);
          let today_date = new Date().setHours(0, 0, 0, 0);
          if (user_info_res.data[0]["subscription_status"] === "cancelled" && today_date > plan_end_date_without_time){
            set_show_pushmode_bar(true);
          } else {
            set_show_pushmode_bar(false);
          }
          return true;
        }
      }
    } catch(err){
      console.log('TopNotificationBanner', err)
    }
  }

// Event listener on path change, show or hide question counter bar.
useEffect(() => {
  if (location.pathname === "/quiz") {
    checkShowQuestionBar();
  } else {
    set_show_question_counter_bar(false);
  }
}, [location.pathname]);

async function checkShowQuestionBar() {
  let api_response = await GetUserInfoAPI();
  if (api_response.data[0]["plan_type"] === "free" && api_response.data[0]["payment_history_count"] <= 0) {
    set_show_question_counter_bar(true);
  } else {
    set_show_question_counter_bar(false);
  }
}

// Event listener on window resize
useEffect(() => {
  const handleResize = () => {
    set_window_width(window.innerWidth);
  };
  window.addEventListener("resize", handleResize);
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

  return (
    <>
      {show_pushmode_bar ? (
        <>
          <section className={style["pushmode-end-text1"]}>
            The push mode has ended.
            <Link
              to="/pushmode"
              style={{
                textDecoration: "none",
              }}
            >
              <span className={style["pushmode-end-text2"]}>
                {" "}
                Go unlimited now.
              </span>
            </Link>
          </section>
        </>
      ) : (
        <></>
      )}

      <section
        style={
          show_question_counter_bar ? { display: "flex" } : { display: "none" }
        }
        className={style["question-counter-container"]}
      >
        <div className={style["question-counter-content"]}>
          <p>
            <span
              id="quiz_freemium_topbar_go_to_push_mode"
              onClick={() => {
                navigate("/pushmode");
              }}
            >
              Unlock all questions now.{" "}
            </span>
            {questionData.length ? (
              <>
                Free questions seen ({" "}
                {questionData[0].seen_question_count
                  ? questionData[0].seen_question_count
                  : 0}{" "}
                /{" "}
                {questionData[0].total_question_count
                  ? questionData[0].total_question_count
                  : 0}
                )
              </>
            ) : (
              <>Free questions seen (0/0)</>
            )}
            <img
              onMouseOver={() => {
                question_counter_helptext.current.style.display = "block";
              }}
              onMouseOut={() => {
                question_counter_helptext.current.style.display = "none";
              }}
              style={
                window_width <= 500 ? { display: "none" } : { display: "block" }
              }
              src={questionIcon}
              alt="question icon"
            />
          </p>
          <div
            ref={question_counter_helptext}
            className={style["question-counter-info-text"]}
          >
            In free mode you see questions only from the first pages of your
            document.
            <strong
              onClick={() => {
                navigate("/pushmode");
              }}
            >
              Go push mode to go unlimited
            </strong>
          </div>
        </div>
      </section>
    </>
  );
}

// // Event listener on local storage value change
// useEffect(() => {
//     const handleStorageChange = () => {
//         // let stored_user_info = localStorage.getItem("user_info");
//         // if(stored_user_info !== undefined && stored_user_info !== null && stored_user_info !== ""){
//         //     set_subscription_status(stored_user_info[0]["subscription_status"])
//         //     stored_user_info = JSON.parse(stored_user_info);
//         //     let plan_end_date_with_time = new Date(stored_user_info[0]["plan_end_date"])
//         //     let plan_end_date_without_time = plan_end_date_with_time.setHours(0,0,0,0);
//         //     let today_date = new Date().setHours(0,0,0,0);
//         //     if(stored_user_info[0]["subscription_status"] === "cancelled" && today_date > plan_end_date_without_time){
//         //         // paymentHistory();
//         //         set_show_pushmode_bar(true);
//         //     } else {
//         //         set_show_pushmode_bar(false);
//         //     }
//         // }
//         let temp_current_question_content = localStorage.getItem("question_seen_count");
//         console.log(temp_current_question_content)
//         if(temp_current_question_content !== undefined && temp_current_question_content !== null && temp_current_question_content !== ""){
//             console.log('value changed')
//             // temp_current_question_content = JSON.parse(temp_current_question_content);
//             // set_current_question_content(temp_current_question_content);
//         } else {
//             // set_current_question_content([])
//         }

//     };
//     window.addEventListener('storage', handleStorageChange);
//     return () => {
//         window.removeEventListener('storage', handleStorageChange);
//     };
// }, []);
