import React,{useState, useRef, useEffect} from 'react'
import { AiOutlineEye,AiOutlineEyeInvisible } from 'react-icons/ai';
import style from "./Profile.module.scss";
import { GetUserInfoAPI, updateUserProfile } from "../../helpers/requests";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from '../../Components/Sidebar/Sidebar';


export default function Profile(){
// const [eye,seteye]=useState(false)
// const [editname,setEditName]=useState(true)
// const [editPass,setEditpass]=useState(true)
// const [profileval,setProfileval]=useState({email:'',password:'',})
const [show_password,set_show_password]=useState(false)
const [username_editable,set_username_editable]=useState(true)
const [password_editable,set_password_editable]=useState(true)
const [username_data,set_username_data]=useState(true)
const [auth_provider,set_auth_provider]=useState("email")
const username_input = useRef(null);
const password_input = useRef(null);



    useEffect(()=>{
        fetchUserInformation();
    }, []);

    async function fetchUserInformation(){
        let api_response =  await GetUserInfoAPI();
        username_input.current.value = api_response["data"][0]["name"];
        set_username_data(api_response["data"][0]["name"]);
        set_auth_provider(api_response["data"][0]["auth_provider"]);
    }

    // const handleChange = (e) =>{
    //     const {name,value} = e.target
    //     setProfileval((preval)=>({
    //         ...preval,
    //         [name]:value,
    //     }));
    // }




    function handle_username_edit(){
        set_username_editable(!username_editable);
        if(username_editable){
            username_input.current.style.backgroundColor = "#fff";
            username_input.current.style.pointerEvents = "auto";
        } else{
            username_input.current.style.backgroundColor = "#c3c6cc";
            username_input.current.style.pointerEvents = "none";
        }
    }
    function handle_password_edit(){
        set_password_editable(!password_editable);
        if(password_editable){
            password_input.current.style.backgroundColor = "#fff";
            password_input.current.style.pointerEvents = "auto";
        } else{
            password_input.current.style.backgroundColor = "#c3c6cc";
            password_input.current.style.pointerEvents = "none";
        }
    }

    async function handle_save_btn(){
        let request_json = {}
        


        if(username_input.current.value === username_data && password_input.current.value === ""){
            toast.error("Make some changes to update!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: true,
            });
        } else {
            if(username_input.current.value !== username_data){
                request_json["username"] = username_input.current.value;
            }
            if(password_input.current.value !== ""){
                if(password_input.current.value.length >= 8){
                    request_json["password"] = password_input.current.value;
                } else {
                    toast.error("Password lenght must be greater than 8 digits", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: true,
                    });
                }
            }
            let api_response = await updateUserProfile(request_json)
            if(api_response.message.toLowerCase() === "user profile updated successfully"){
                toast.success("user profile updated successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: true,
                });
            } else {
                toast.error("can't update profile", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: true,
                });
            }
        }
    }


    return (
        <>
        {/* <Sidebar/> */}
        <main className={style["container"]}>
            <section className={style["content"]}>
                <h2 className={style["title"]}>Your Profile</h2>
                <div className={style["input-box-container"]}>
                    <article className={style["input-fields"]}>
                        <input ref={username_input} type="email" />
                        <p onClick={()=>{handle_username_edit()}}>
                            Edit Username
                        </p>
                    </article>
                    <article className={style["input-fields"]} 
                        >
                        {/* <input ref={password_input} type={show_password? "text" : "password"} /> */}
                        <input ref={password_input} type='text' />
                        {/* <aside onClick={()=>{set_show_password(!show_password)}}>
                            {show_password? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}
                        </aside> */}
                        <p
                            style={auth_provider === "email" ? {display: "none"} : { display: "block"}}>
                            Password change is not required for Google SSO users
                        </p>
                        <p onClick={()=>{handle_password_edit()}}
                            style={auth_provider === "email" ? {display: "block"} : { display: "none"}}>
                            Change Password
                        </p>
                    </article>
                </div>
                <button id="profile_save" onClick={()=>{handle_save_btn()}} className={style["save-btn"]}>Save</button>
            </section>
        </main>
        <ToastContainer />
        
    {/*         
        <div className='main_login' style={{display: "none"}}>
            <h2 className="login_title">
                Your Profile
            </h2>
            <div className="detail">Login details</div>
            <div className="login_input">
            <input onChange={handleChange} name='email' value={profileval.email} className= {!editname ? 'form_input':'dis_input'} disabled={editname} type={'text'} placeholder='E mail' />
            <p className='edit' onClick={()=>setEditName(!editname)}>Edit Username</p>
            <div className="main_pass" style={{pointerEvents:editPass?'none':'unset'}} >
            <input onChange={handleChange} name='password' value={profileval.password} className= {!editPass ? 'form_input':'dis_input'} type={eye ? 'text':'password'} placeholder='Password' />
            <div className="eye" onClick={()=>seteye(!eye)}>
            { eye ? <AiOutlineEye/> : <AiOutlineEyeInvisible/> }
            </div>
            </div>
            <p className='edit' onClick={()=>setEditpass(!editPass)}>Edit Password</p>
            </div>
            <button className='button'>Save</button>
        </div>
    */}

        </>
    )
}