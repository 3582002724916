import { createContext, useState } from "react";

export const Message_data = createContext(null);
export const CurrentUserInfo = createContext(null);

export default function ContextStore({children}){
    const [questionData, setQuestionData] = useState([]);
    const [sharedUserInfo, setSharedUserInfo] = useState({});

    return(
        <Message_data.Provider value={{questionData, setQuestionData}}>
            <CurrentUserInfo.Provider value={{sharedUserInfo, setSharedUserInfo}}>
                {children}
            </CurrentUserInfo.Provider>
        </Message_data.Provider>
    );
}