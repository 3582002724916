import { useRef, useEffect, useState, useContext } from "react";
import { loginRequest, handleSSOLoginData, getUserMetadata, GetUserInfoAPI } from "../../helpers/requests";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getApiUrl } from "../../helpers/requests";
import { CurrentUserInfo } from "../ContextStore/ContextStore";

export default function GoogleAuthBtn() {
    const { setSharedUserInfo } = useContext(CurrentUserInfo);
    const googleButton = useRef(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    var apiUrl = getApiUrl();
    const [reload_google_btn, set_reload_google_btn] = useState(false);


const loadScript = (src) =>
new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve()
    const script = document.createElement('script')
    script.src = src
    script.async = 1;
    script.onload = () => resolve()
    script.onerror = (err) => reject(err)
    document.body.appendChild(script)
})

useEffect(()=>{
    setTimeout(()=>{
        set_reload_google_btn(true);
    }, 1000)
},[])

useEffect(() => {
    let google_sso_public_key = "";
    if(apiUrl === "https://app.dende.ai/api" || apiUrl === "https://staging.dende.ai/api"){
        google_sso_public_key = "942884516634-8ccmvv8pl2jho2ggu3p336lskivhm59n.apps.googleusercontent.com";
    } else {
        google_sso_public_key = "1065254071382-74lpi6ks0kmf6uqtvpmotsiq7l41c5id.apps.googleusercontent.com";
    }
    const src = 'https://accounts.google.com/gsi/client'
    const id = google_sso_public_key
    loadScript(src)
    .then(() => {
        /*global google*/
        google.accounts.id.initialize({
            client_id: id,
            select_by: "btn",
            callback: handleCredentialResponse,
        })
        google.accounts.id.renderButton(
            googleButton.current,
            { theme: 'outline', text: 'signin_with',  shape: 'pill', size: "large"}
        )
    })
    .catch(console.error)
    return () => {
        const scriptTag = document.querySelector(`script[src="${src}"]`)
        if (scriptTag) document.body.removeChild(scriptTag)
    }
}, [])

useEffect(() => {
    let google_sso_public_key = "";
    if(apiUrl === "https://app.dende.ai/api" || apiUrl === "https://staging.dende.ai/api"){
        google_sso_public_key = "942884516634-8ccmvv8pl2jho2ggu3p336lskivhm59n.apps.googleusercontent.com";
    } else {
        google_sso_public_key = "1065254071382-74lpi6ks0kmf6uqtvpmotsiq7l41c5id.apps.googleusercontent.com";
    }
    const src = 'https://accounts.google.com/gsi/client'
    const id = google_sso_public_key
    loadScript(src)
    .then(() => {
        /*global google*/
        google.accounts.id.initialize({
            client_id: id,
            select_by: "btn",
            callback: handleCredentialResponse,
        })
        google.accounts.id.renderButton(
            googleButton.current,
            { theme: 'outline', text: 'signin_with',  shape: 'pill', size: "large"}
        )
    })
    .catch(console.error)
    return () => {
        const scriptTag = document.querySelector(`script[src="${src}"]`)
        if (scriptTag) document.body.removeChild(scriptTag)
    }
}, [reload_google_btn])

async function handleCredentialResponse(response) {

    if (response.credential) {
        let userMetadataResponse = await getUserMetadata();
        let loginRequestData = {
            "auth_token": response.credential,
            "metadata": userMetadataResponse
        }
        fetch(`${apiUrl}/accounts/google`,
            {
            method: "POST",
            body: JSON.stringify(loginRequestData),
            headers: {
            'Content-Type': 'application/json; charset=utf-8'
            }
        })
        .then((res) => res.json())
        .then((res) => {
            // quiz 1st question answered flag set
            if (res.data.isanswered_first_question === true){
                localStorage.setItem('eQuestionFlag', true)
            } else {
                localStorage.setItem('eQuestionFlag', false)
            }           
            handleMarketingData(res, userMetadataResponse)
            let tempRouteUrl = String(location.pathname);
            processGoogleSSOResponse({routeURL : tempRouteUrl, googleSSOData : res})
        })
    }
}


function handleMarketingData(api_response, metadata){
    try{
        if(api_response.message.toLowerCase() === "user login success"){
        
            // google tag manager
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "login",
                user_id: String(api_response?.data?.id) ?? "",
                user_email: api_response?.data?.email ?? "",
            });
        } 
        else if (api_response.message.toLowerCase() === "new user created"){
            // google tag manager
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: "sign_up",
                method: "google",
                user_id: String(api_response?.data?.id) ?? "",
                user_email: api_response?.data?.email ?? "",
                ip_address: metadata?.ip_address ?? "",
            });
        }
    } catch (error) {
        console.log('google login button', error);
    }
}

function createIndexedDB(){
    // delete existing DB
    let deleteDB = indexedDB.deleteDatabase("dendeLocalDB");
    deleteDB.onerror = function(event) {
        console.log("Error deleting database.", event.target.errorCode);
    };
    deleteDB.onsuccess = function(event) {
        console.log("Database deleted successfully");
    };
    
    let db;
    let request = indexedDB.open("dendeLocalDB", 1);
    request.onupgradeneeded = function(event) {
        db = event.target.result;
        db.createObjectStore("table1", { keyPath: 'id' });
    };
    request.onsuccess = function(event) {
        console.log("DB created success")
    };
    request.onerror = function(event) {
        // Log the error code
        toast.error("indexedDB error create db", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        });
        console.log("Error opening database: " + event.target.errorCode);
    };	
}


const processGoogleSSOResponse = async (args) => {
    const {routeURL, googleSSOData} = args;

    let res1 = await handleSSOLoginData(googleSSOData);

    if(res1.status){
        setSharedUserInfo({})

        localStorage.setItem('quiz_hints_guide_text_hover_count', 0);
        localStorage.setItem('quiz_hints_guide_text_show_count', 0);
        let tempResponseMsg = String(res1.message).toLowerCase();
        let toastText = (tempResponseMsg.includes("login success")) ? "Login Successfully" :
                        (tempResponseMsg.includes("user created") )? "Signup Successfully": "";
        toast.success(toastText, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: true,
        });
        createIndexedDB();
        let user_info_response = GetUserInfoAPI();
        if(res1.data.resource_count <= 0){
            navigate("/initial-upload");
        } else {
            navigate("/home");
        }
    } 
    else {
        toast.error(res1.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: true,
        });
    }
}




    return (
        <>
            <div  ref={googleButton} id='google-ref'></div>
        </>
    )
}